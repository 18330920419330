import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export const query = graphql`
  query {
    institute: contentfulAsset(
      id: { eq: "3a6e33ef-d5a3-5522-8268-ae771f2cbcce" }
    ) {
      id
      title
      file {
        fileName
        url
        contentType
      }
    }
  }
`

const Contact = ({ data }) => {
  const { institute } = data
  return (
    <Layout>
      <div className="container contact">
        <div className="section">
          <figure className="image is-3by1">
            <img
              className="institute-image"
              src={institute.file.url}
              alt="Josep Carreras Leukaemia Research Institute"
            />
          </figure>
        </div>
        <div className="section">
          <div className="columns">
            <div className="column is-one-third">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/Josep+Carreras+Leukaemia+Research+Institute+(IJC)/@41.4857901,2.2330618,15z/data=!4m5!3m4!1s0x0:0x54624ef1b1475f04!8m2!3d41.4855135!4d2.2397781"
              >
                <figure className="image map-image">
                  <img
                    src="/institute-location.png"
                    alt="Josep Carreras Leukaemia Research Institute location Google Maps"
                  />
                </figure>
              </a>
            </div>
            <div className="column">
              <h5 className="title is-5">Here is where to find us.</h5>
              <p>Campus ICO-Germans Trias i Pujol</p>
              <p className="content">
                Josep Carreras Leukaemia Research Institute Can Ruti Campus Ctra
                de Can Ruti, Camí de les Escoles s/n 08916 Badalona, Barcelona
              </p>
              <p className="content">
                Office (+34)-935572800 ext 4240
                <br />
                Lab (+34)-935572800 ext 4241{" "}
              </p>
              <a
                href="mailto:avaquero@carrerasresearch.org"
                className="button is-large"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
